import { TrackedButton } from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { GOOGLE_MAPS_API_KEY } from '../../../constants'
import generateGoogleMapUrl from '../../../utils/googleMapUrl'
import ScheduleCall from '../../SharedComponents/ScheduleCall'
import { useCalendly } from '../../utils'
import PrelimOfferSection from './PrelimOfferSection'
import OfferPageContext from '../OfferPageContext'

type PrelimHeroSectionProps = {
  leadUuid: string
  fullName: string
  phoneNumber: string
  street: string
  cityStateZipcode: string
  shouldHideNeighbors: boolean
  leadNameOverride?: string
}

const PrelimHeroSection = (props: PrelimHeroSectionProps) => {
  const {
    fullName,
    street,
    cityStateZipcode,
    shouldHideNeighbors,
    leadNameOverride,
  } = props
  const [streetViewUrl, setStreetViewUrl] = useState('')

  const displayName = leadNameOverride || fullName.split(' ')[0]

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const context = useContext(OfferPageContext)
  const trackingName = 'prelim-hero-schedule-call'

  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'prelim_hero_success',
    scheduleUrl: context.calendlyLink || '',
  })

  useEffect(() => {
    const streetviewMetadataUrl = generateGoogleMapUrl({
      type: 'streetview/metadata',
      location: `${street} ${cityStateZipcode}`,
      key: GOOGLE_MAPS_API_KEY,
    })
    fetch(streetviewMetadataUrl)
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        if (resultData.status === 'OK') {
          setStreetViewUrl(
            generateGoogleMapUrl({
              type: 'streetview',
              location: `${street} ${cityStateZipcode}`,
              size: isTablet ? '544x188' : '352x496',
              key: GOOGLE_MAPS_API_KEY,
              fov: 80,
            })
          )
        }
      })
  }, [street, cityStateZipcode, isTablet])

  let hiddenNeighborsText = `You're joining a professionally managed, diversified portfolio.`
  if (isMobile) {
    hiddenNeighborsText = `You're joining our portfolio.`
  }

  return (
    <PrelimOfferSection
      sectionId="offer"
      nextId="portal-preview"
      nextCta={
        shouldHideNeighbors
          ? hiddenNeighborsText
          : 'A window into hassle-free ownership'
      }
    >
      {streetViewUrl && (
        <Grid item sm={6} md={4} order={{ md: 1, sm: 2, xs: 2 }}>
          <Box
            component="img"
            width={{
              xs: '320px',
              sm: '100%',
              md: '352px',
            }}
            height={{
              xs: '120px',
              sm: 'auto',
              md: '496px',
            }}
            src={streetViewUrl}
            sx={{
              filter: 'drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.16))',
              borderRadius: '12px',
            }}
          />
        </Grid>
      )}
      {streetViewUrl && !isTablet && (
        <Grid item sm={6} md={1} order={{ md: 2 }} />
      )}
      <Grid
        item
        sm={6}
        md={!streetViewUrl ? 12 : 7}
        order={{ md: 3, sm: 1, xs: 1 }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={{
            xs: '16px',
            sm: '32px',
            md: '32px',
          }}
        >
          <Typography variant="h3" color="primary.main">
            Hi{fullName ? ` ${displayName}` : ''},{' '}
          </Typography>
          <Typography variant="h2" color="primary.main">
            We&apos;re working on your offer for {street}, {cityStateZipcode}.
          </Typography>
          <Box>
            <Typography variant="p1" display="inline">
              We&apos;ll have an offer ready for you by tomorrow. To view your
              offer, check your email, or bookmark this link and come back to
              see updates.
            </Typography>
          </Box>
          <ScheduleCall {...otherCalendlyProps} />
          <TrackedButton
            variant="tertiary"
            size="smallForm"
            onClick={onOpenCalendly}
            trackingConfig={{ name: `${trackingName}-clicked` }}
          >
            Schedule a call
          </TrackedButton>
        </Box>
      </Grid>
    </PrelimOfferSection>
  )
}

export default PrelimHeroSection

PrelimHeroSection.defaultProps = {
  leadNameOverride: '',
}
