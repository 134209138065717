import { OmLeftArrowFilledIcon, TrackedLink } from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import MobilePreview from '../../../images/mobile-preview.webp'

type PrelimOfferSectionProps = {
  children: React.ReactNode
  sectionId: string
  nextCta?: string
  nextId?: string
  background?: string
  showMobilePreview?: boolean
  isGenericOfferPage?: boolean
}

const PrelimOfferSection = (props: PrelimOfferSectionProps) => {
  const {
    children,
    sectionId,
    nextCta,
    nextId,
    background,
    showMobilePreview,
    isGenericOfferPage,
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const handleNext = () => {
    const el = document.getElementById(nextId as string)
    const yOffset = -32
    const y = el?.getBoundingClientRect().top! + window.pageYOffset + yOffset

    window.scrollTo({
      behavior: 'smooth',
      top: y,
    })

    if (isMobile) {
      window.location.href = `#${nextId}`
    }
  }

  let columns = 12
  if (isMobile) {
    columns = 4
  } else if (isTablet) {
    columns = 6
  }

  return (
    <Box
      id={sectionId}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
      sx={{
        backgroundImage: background ? `url("${background}")` : 'unset',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {showMobilePreview && (
        <Box
          component="img"
          src={MobilePreview}
          position="absolute"
          bottom="0px!important"
          left="0px"
          width="325px"
          height="516px"
        />
      )}
      <Box
        width={{
          xs: '320px',
          sm: 'calc(100% - 208px)',
          md: '1120px',
        }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt={{
          xs: '32px',
          sm: '64px',
          md: '64px',
        }}
        pb={{
          xs: isGenericOfferPage ? '0px' : '32px',
          sm: isGenericOfferPage ? '0px' : '64px',
          md: isGenericOfferPage ? '0px' : '64px',
        }}
        pl={{
          xs: 'unset',
          sm: 'unset',
          md: 'unset',
        }}
        pr={{
          xs: 'unset',
          sm: 'unset',
          md: 'unset',
        }}
        gap={{
          xs: '32px',
          sm: '32px',
          md: '48px',
        }}
      >
        <Grid
          container
          spacing={{
            xs: 2,
            sm: 6,
            md: 6,
          }}
          columns={columns}
        >
          {children}
        </Grid>
        {nextId && (
          <TrackedLink onClick={handleNext} sx={{ zIndex: 1 }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="8px"
              borderRadius="24px"
              paddingRight="16px"
              sx={{
                backgroundColor: '#fff',
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                height="100%"
                sx={{ transform: 'rotate(-90deg)' }}
              >
                <OmLeftArrowFilledIcon />
              </Box>
              <Typography variant="p2" color="primary.main">
                {nextCta}
              </Typography>
            </Box>
          </TrackedLink>
        )}
      </Box>
    </Box>
  )
}

export default PrelimOfferSection

PrelimOfferSection.defaultProps = {
  nextCta: '',
  nextId: '',
  background: null,
  showMobilePreview: false,
  isGenericOfferPage: false,
}
