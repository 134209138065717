import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useInView } from '../../utils'
import { OfferPageData } from '../offerPageTypes'
import PrelimHeroSection from './PrelimHeroSection'
import PrelimNextStepsSection from './PrelimNextStepsSection'
import PrelimOfferNav from './PrelimOfferNav'
import PrelimPreviewSection from './PrelimPreviewSection'

type PreliminaryOfferPageProps = {
  leadUuid: string
  pageData: OfferPageData
}

const PreliminaryOfferPage = (props: PreliminaryOfferPageProps) => {
  const { leadUuid, pageData } = props

  const [shouldHideNeighbors] = useState(false)

  const {
    fullName,
    phoneNumber,
    street,
    city,
    state,
    cityStateZipcode,
    leadNameOverride,
  } = pageData

  const [isGeneric] = useState(!street || !city || !state)

  const currentSection = useInView([
    'offer',
    'neighbors',
    'portfolio',
    'stories',
    'portal-preview',
    'next-steps',
    'important-resources',
  ])

  return (
    <>
      <Box
        height={{
          xs: '0px',
          sm: '40px',
          md: '40px',
        }}
      />
      <Box
        position="sticky"
        top={{ xs: '0px', sm: '0px', md: '0px' }}
        zIndex={2}
      >
        <PrelimOfferNav currentSection={currentSection} isGeneric={isGeneric} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop={{
          xs: '-32px',
          md: '-16px',
        }}
      >
        <PrelimHeroSection
          leadUuid={leadUuid}
          fullName={fullName}
          phoneNumber={phoneNumber}
          street={street}
          cityStateZipcode={cityStateZipcode}
          shouldHideNeighbors={shouldHideNeighbors}
          leadNameOverride={leadNameOverride}
        />
        <PrelimPreviewSection isGeneric={isGeneric} />
        {/* {isGeneric ? (
          <GenericPrelimImportantResources
            slackThreadUrl={slackThreadUrl}
            staticDocumentOverrides={staticDocumentOverrides}
            leadDocuments={leadDocuments}
          />
        ) : ( */}
        <PrelimNextStepsSection />
        {/* )} */}
      </Box>
    </>
  )
}

export default PreliminaryOfferPage
