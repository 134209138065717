import { InfoIcon } from '@flock/shared-ui'
import { Box, Paper, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { OfferExchangePresentationalProps } from './offerExchangeTypes'

const OfferExchangeTablet = (props: OfferExchangePresentationalProps) => {
  const {
    finalOffer,
    mortgage,
    cashTakeout,
    rentReduction,
    daysInRemodelDeduction,
    brokerCommission,
    onboardingFee,
    closingCosts,
    displayedClosingCosts,
    displayedOnboardingFee,
    equityValue,
    companyName,
  } = props

  return (
    <Box display="flex" flexDirection="row" gap="24px">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Paper
          sx={{
            padding: '16px',
            backgroundColor: 'gray1.main',
            borderRadius: '12px',
            height: '100%',
            display: 'flex',
            width: '100%',
          }}
        >
          <Box display="flex" flexDirection="column" gap="4px" width="100%">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="p2">Offer Price</Typography>
              <Typography variant="p2">
                ${finalOffer?.toLocaleString()}
              </Typography>
            </Box>
            {mortgage ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="p2">Mortgage Balance</Typography>
                  <Tooltip
                    title={`${companyName} will pay off any existing mortgage at closing.`}
                    arrow
                    placement="right"
                    enterTouchDelay={0}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        height: '24px',
                        alignItems: 'center',
                      }}
                    >
                      <InfoIcon fill="#B6BABE" width="12px" height="12px" />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography variant="p2">
                  -${Math.round(mortgage).toLocaleString()}
                </Typography>
              </Box>
            ) : null}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="2px"
              >
                <Typography variant="p2">
                  Onboarding Fee (
                  {((onboardingFee - brokerCommission) * 100).toFixed(2)}
                  %)
                </Typography>
              </Box>
              <Typography variant="p2">-${displayedOnboardingFee}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="2px"
              >
                <Typography variant="p2">
                  Closing Costs ({(closingCosts * 100).toFixed(2)}
                  %)
                </Typography>
                <Tooltip
                  title="These costs may vary and will be finalized by the title company."
                  arrow
                  placement="right"
                  enterTouchDelay={0}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      height: '24px',
                      alignItems: 'center',
                    }}
                  >
                    <InfoIcon fill="#B6BABE" width="12px" height="12px" />
                  </Box>
                </Tooltip>
              </Box>
              <Typography variant="p2">-${displayedClosingCosts}</Typography>
            </Box>
            {rentReduction ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="p2">Rent Reduction</Typography>
                <Typography variant="p2">
                  -${rentReduction.toLocaleString()}
                </Typography>
              </Box>
            ) : null}
            {cashTakeout ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="p2">Cash Takeout</Typography>
                <Typography variant="p2">
                  -${cashTakeout.toLocaleString()}
                </Typography>
              </Box>
            ) : null}
            {daysInRemodelDeduction ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="p2">Days in Remodel Reduction</Typography>
                <Typography variant="p2">
                  -${Math.round(daysInRemodelDeduction).toLocaleString()}
                </Typography>
              </Box>
            ) : null}
            {brokerCommission ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="p2">
                  Broker Commission ({(brokerCommission * 100).toLocaleString()}
                  %)
                </Typography>
                <Typography variant="p2">
                  -${Math.round(finalOffer * brokerCommission).toLocaleString()}
                </Typography>
              </Box>
            ) : null}
            <Box sx={{ width: '100%', borderTop: '1px solid grey' }} />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="p2" fontWeight="600" color="primary.main">
                Equity Value in Fund
              </Typography>
              <Typography variant="p2" fontWeight="600" color="primary.main">
                ${equityValue.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default OfferExchangeTablet
