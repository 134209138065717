import { Core_TransactionType } from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMediaQuery, useTheme } from '@mui/material'
import { useContext } from 'react'
import {
  getTimeForZip,
  hideExpirationLeadStages,
  useCalendly,
} from '../../utils'
import OfferPageContext from '../OfferPageContext'
import { LeadStatus } from '../offerPageTypes'
import {
  OfferTasksProps,
  OfferTasksPresentationalProps,
} from './offerTasksTypes'

type CategorizedTasks = {
  todo: string[]
  completed: string[]
}

const getCategorizedTasks = (
  leadStatus: LeadStatus,
  transactionType: string
): CategorizedTasks => {
  const actionItems = [
    'Provide property details.',
    'Get an initial offer.',
    'Discuss your initial offer and schedule an inspection.',
    transactionType === Core_TransactionType.TransactionTypePortfolio_721
      ? 'Schedule a portfolio inspection.'
      : 'Fill out your pre-inspection survey.',
    'Review inspection results and final offer with a real estate advisor.',
    'Sign Contribution Agreement.',
    'Close and join the fund.',
  ]
  const leadStatusIndexMap = {
    [LeadStatus.GET_AN_OFFER]: 1,
    [LeadStatus.DISCUSS_YOUR_OFFER]: 2,
    [LeadStatus.SCHEDULE_AN_INSPECTION_PRE_INSPECTION_BOOKED]: 3, // this is 3 because we add to action items if this is it.
    [LeadStatus.SCHEDULE_AN_INSPECTION_BOOKED]: 3,
    [LeadStatus.SCHEDULE_AN_INSPECTION_POST_INSPECTION]: 3,
    [LeadStatus.REVIEW_FINAL_OFFER]: 3,
    [LeadStatus.SIGN_CONTRIBUTION_AGREEMENT]: 4,
    [LeadStatus.CLOSING_PROCESSES]: 5,
  }
  // if it is naturally inspection booked or post inspection, then we will have inspection date.
  // but we need to consider if it has been overriden to be either of those.
  if (leadStatus === LeadStatus.SCHEDULE_AN_INSPECTION_PRE_INSPECTION_BOOKED) {
    actionItems[2] = 'Discuss your initial offer.'
    actionItems.splice(3, 0, 'Schedule an inspection.')
  }

  const maxTaskIndex = Math.max(
    ...Object.values(leadStatusIndexMap).map((i) => i)
  )
  const currIndex = leadStatusIndexMap[leadStatus]
  const todo = currIndex < maxTaskIndex ? actionItems.slice(currIndex + 1) : []
  const completed = actionItems.slice(0, currIndex).reverse()
  return {
    todo,
    completed,
  }
}

const useOfferTasks: (
  props: OfferTasksProps
) => OfferTasksPresentationalProps = (props: OfferTasksProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const {
    leadStatus,
    leadStage,
    zipcode,
    inspectionDate,
    expiryDate,
    leadUuid,
    transactionType,
  } = props

  const context = useContext(OfferPageContext)

  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'mobile-offer-page-action-items',
    scheduleUrl: context.calendlyLink as string,
  })

  const displayedDateTime = getTimeForZip(inspectionDate, zipcode)

  let isExpired = new Date().getTime() > expiryDate.getTime()
  const isValidExpiration = expiryDate.getFullYear() > 2000
  const hideExpiry = hideExpirationLeadStages.includes(leadStage)
  if (!isValidExpiration || hideExpiry) {
    isExpired = false
  }
  const actionTextVar =
    leadUuid === 'cbb65871-80b5-4806-b8b6-9300ccf366d0'
      ? 'Email Ari to request your contribution agreement.'
      : 'Schedule a call to review your inspection results and final offer with a real estate advisor.'
  const onClickFunc =
    leadUuid === 'cbb65871-80b5-4806-b8b6-9300ccf366d0'
      ? () => {
          window.open(
            `mailto:ari@flockhomes.com?subject=Request%20Contribution%20Agreement`
          )
        }
      : onOpenCalendly
  const currentStatus = isExpired ? LeadStatus.GET_AN_OFFER : leadStatus

  const leadStatusDetails: {
    [key: string]: {
      actionText: string
      mobileActionText: string
      description?: string
      onClick?: () => void
    }
  } = {
    [LeadStatus.GET_AN_OFFER]: {
      actionText: isExpired ? 'Request an offer refresh.' : 'Get an offer.',
      mobileActionText: 'Schedule a call',
      description: `We're working on your initial offer - you'll receive it in your email within 24 hours.`,
      onClick: isExpired ? onOpenCalendly : undefined,
    },
    [LeadStatus.DISCUSS_YOUR_OFFER]: {
      actionText: 'Schedule a call to discuss your offer.',
      mobileActionText: 'Schedule a call',
      onClick: onOpenCalendly,
    },
    [LeadStatus.SCHEDULE_AN_INSPECTION_PRE_INSPECTION_BOOKED]: {
      actionText: 'Schedule an inspection.',
      mobileActionText: 'Schedule an inspection.',
      description: `Please contact us to schedule an inspection. We'll prepare a final offer after the inspection is complete.`,
      onClick: onOpenCalendly,
    },
    [LeadStatus.SCHEDULE_AN_INSPECTION_BOOKED]: {
      actionText:
        'Review inspection results and final offer with a real estate advisor (pending).',
      mobileActionText: 'Pending inspection results',
      description: displayedDateTime
        ? `Your inspection is scheduled for ${displayedDateTime}. We'll prepare a final offer after the inspection is complete.`
        : `Your inspection has been scheduled. We'll prepare a final offer after the inspection is complete.`,
    },
    [LeadStatus.SCHEDULE_AN_INSPECTION_POST_INSPECTION]: {
      actionText: `Review inspection results and final offer with a real estate advisor (pending).`,
      mobileActionText: `We're working on your final offer`,
      description: `We are working on your final offer and will reach out as soon as it is ready!`,
    },
    [LeadStatus.REVIEW_FINAL_OFFER]: {
      actionText: actionTextVar,
      mobileActionText: 'Schedule a call',
      onClick: onClickFunc,
    },
    [LeadStatus.SIGN_CONTRIBUTION_AGREEMENT]: {
      actionText: 'Sign your Contribution Agreement.',
      mobileActionText: 'Sign your contribution agreement',
      description: `We've sent you an email with your contribution agreement. Please sign it to move forward.`,
    },
    [LeadStatus.CLOSING_PROCESSES]: {
      actionText: `Check your email for status updates as we work on closing your transaction.`,
      mobileActionText: `Pending closing processes.`,
      description: `We're working on your transaction. Monitor your email as we walk you through the closing process.`,
    },
  }

  const { actionText, mobileActionText, onClick, description } =
    leadStatusDetails[currentStatus]

  const { todo: todoItems, completed: completedItems } = getCategorizedTasks(
    currentStatus,
    transactionType
  )

  return {
    ...props,
    isMobile,
    isTablet,

    todoItems,
    context,
    completedItems,
    actionText,
    mobileActionText,
    onClick,
    description,
    otherCalendlyProps,
  }
}

export default useOfferTasks
